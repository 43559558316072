import { IHomeState } from './home.model';
import { ELogbookGridView } from '../../view/home/logbook-grid-view/logbook-grid-view.model';
import * as Actions from './home.actions';
import { Action } from '@ngrx/store';
import { ILogbook } from '../logbooks/logbooks.model';
import { IHomeLogbook } from '../../view/home/home.model';
import * as _ from 'lodash';

export const homeState: IHomeState = {
  gridViewTab: ELogbookGridView.Network,
  favoriteLogbooksDataLoading: false,
  favoriteLogbooksDataLoaded: false,
  favoriteLogbooks: [],
  ongoingLogbooksDataLoading: false,
  ongoingLogbooksDataLoaded: false,
  ongoingLogbooks: [],
  networkLogbooksDataLoading: false,
  networkLogbooksDataLoaded: false,
  networkLogbooks: [],
  childrenLogbooksDataLoading: false,
  childrenLogbooksDataLoaded: false,
  childrenLogbooks: [],
  networkLogbooksTree: [],
  sidebarCategoryExpandedStatus: null,
  sidebarExpandedStatus: true,
  topLevelLogbook: undefined,
  logbookHomeDetail: null,
  logbookHomeDetailDataLoading: false,
  logbookHomeDetailDataLoaded: false,
  cancelFormSubmission: false,
  cancelFormSubmissionCompleted: false,
  getFormSubmissionHistory: false,
  getFormSubmissionHistoryCompleted: false,
  getFormSubmission: false,
  getFormSubmissionCompleted: false,
  updateFormSubmission: false,
  updateFormSubmissionCompleted: false,
  getFormSubmissions: false,
  getFormSubmissionsCompleted: false,
  formSubmissionsData: [],
  formSubmissionHistory: [],
  formSubmission: null,
  isFromLogbookHomeDetail: false,
  logbook: [],
  getLogbook: false,
  getLogbookCompleted: false,
  tableSettings: [],
  newLogbookExists: false,
  afterChangeParent: false,
  oldNetworkLogbooks: [],
  homeSearchLoading: false,
  homeSearchLoaded: false,
  homeSearchData: [],
  signFieldId: null,
};

export function homeReducer(state: IHomeState = homeState, baseAction: Action): IHomeState {
  const action = baseAction as Actions.HomeActions;
  switch (action.type) {
    case Actions.FAVORITE_LOGBOOKS_DATA_LOADING:
      return {
        ...state,
        favoriteLogbooksDataLoading: true,
        favoriteLogbooksDataLoaded: false,
      };
    case Actions.FAVORITE_LOGBOOKS_DATA_LOADED:
      return {
        ...state,
        favoriteLogbooks: action.payload.data.map((favoriteLogbook: IHomeLogbook) => {
          return {
            ...favoriteLogbook,
            isFavorite: true,
          };
        }),
        favoriteLogbooksDataLoading: false,
        favoriteLogbooksDataLoaded: true,
        networkLogbooks: setIsFavorite(state.networkLogbooks, action.payload.data),
        ongoingLogbooks: setIsFavorite(state.ongoingLogbooks, action.payload.data),
        childrenLogbooks: setIsFavorite(state.childrenLogbooks, action.payload.data),
        topLevelLogbook: state.topLevelLogbook
          ? ({
            ...state.topLevelLogbook,
            states: _.orderBy(state.topLevelLogbook.states, 'ordering'),
              isFavorite: action.payload.data.some(
                (favorite: IHomeLogbook) => favorite.id === state.topLevelLogbook?.id,
              ),
            } as IHomeLogbook)
          : undefined,
        ...(state.logbookHomeDetail && {
          logbookHomeDetail: {
            ...state.logbookHomeDetail,
            ...(state.logbookHomeDetail?.logbook && {
              logbook: {
                ...state.logbookHomeDetail?.logbook,
                isFavorite: action.payload.data.some(
                  (favorite: IHomeLogbook) => favorite.id === state.logbookHomeDetail?.logbook?.id,
                ),
              },
            }),
          },
        }),
        logbook: setIsFavorite(action.payload.data, state.favoriteLogbooks),
      };
    case Actions.ONGOING_LOGBOOKS_DATA_LOADING:
      return {
        ...state,
        ongoingLogbooksDataLoading: true,
        ongoingLogbooksDataLoaded: false,
      };
    case Actions.ONGOING_LOGBOOKS_DATA_LOADED:
      return {
        ...state,
        ongoingLogbooks: _.orderBy(setIsFavorite(action.payload.data, state.favoriteLogbooks), 'name'),
        ongoingLogbooksDataLoading: false,
        ongoingLogbooksDataLoaded: true,
      };
    case Actions.NETWORK_LOGBOOKS_DATA_LOADING:
      return {
        ...state,
        afterChangeParent: Boolean(action.afterChangeParent),
        networkLogbooksDataLoading: true,
        networkLogbooksDataLoaded: false,
      };
    case Actions.NETWORK_LOGBOOKS_DATA_LOADED:
      const oldNetworkLogbooks: IHomeLogbook[] = _.cloneDeep(state.networkLogbooks);
      const networkLogbooks: IHomeLogbook[] = _.orderBy(setIsFavorite(action.payload.data, state.favoriteLogbooks), [
        'orderBy',
        'name',
      ]);
      const newLogbookExists: boolean =
        !_.isEqual(oldNetworkLogbooks ?? [], networkLogbooks ?? []) || state.afterChangeParent;

      return {
        ...state,
        networkLogbooks,
        oldNetworkLogbooks,
        newLogbookExists,
        networkLogbooksTree: newLogbookExists ? [] : state.networkLogbooksTree,
        networkLogbooksDataLoading: false,
        networkLogbooksDataLoaded: true,
      };
    case Actions.CHILDREN_LOGBOOKS_DATA_LOADING:
      return {
        ...state,
        childrenLogbooksDataLoading: true,
        childrenLogbooksDataLoaded: false,
      };
    case Actions.CHILDREN_LOGBOOKS_DATA_LOADED:
      return {
        ...state,
        childrenLogbooks: _.orderBy(setIsFavorite(action.payload.data, state.favoriteLogbooks), ['orderBy', 'name']),
        childrenLogbooksDataLoading: false,
        childrenLogbooksDataLoaded: true,
      };
    case Actions.GRID_VIEW_CHANGED:
      return {
        ...state,
        gridViewTab: action.payload,
        topLevelLogbook: action.parentLogbook,
      };
    case Actions.LOGBOOK_HOME_DETAIL_DATA_LOADING:
      return {
        ...state,
        logbookHomeDetailDataLoading: true,
        logbookHomeDetailDataLoaded: false,
      };
    case Actions.LOGBOOK_HOME_DETAIL_DATA_LOADED:
      return {
        ...state,
        logbookHomeDetail: {
          ...action.payload,
          logbook: {
            ...action.payload.logbook,
            states: _.orderBy(action.payload.logbook.states, 'ordering'),
            isFavorite: state.favoriteLogbooks?.some(
              (favorite: IHomeLogbook) => favorite.id === action.payload.logbook.id,
            ),
          },
        },
        formSubmissionsData: action.payload.formSubmissionTableData,
        logbookHomeDetailDataLoading: false,
        logbookHomeDetailDataLoaded: true,
      };
    case Actions.CANCEL_FORM_SUBMISSION:
      return {
        ...state,
        cancelFormSubmission: true,
        cancelFormSubmissionCompleted: false,
      };
    case Actions.CANCEL_FORM_SUBMISSION_COMPLETED:
      return {
        ...state,
        cancelFormSubmissionCompleted: true,
        cancelFormSubmission: false,
      };
    case Actions.GET_FORM_SUBMISSION_HISTORY:
      return {
        ...state,
        getFormSubmissionHistory: true,
        getFormSubmissionHistoryCompleted: false,
      };
    case Actions.GET_FORM_SUBMISSION_HISTORY_COMPLETED:
      return {
        ...state,
        formSubmissionHistory: action.payload,
        getFormSubmissionHistoryCompleted: true,
        getFormSubmissionHistory: false,
      };
    case Actions.GET_FORM_SUBMISSION:
      return {
        ...state,
        getFormSubmission: true,
        getFormSubmissionCompleted: false,
      };
    case Actions.GET_FORM_SUBMISSION_COMPLETED:
      return {
        ...state,
        formSubmission: action.payload,
        getFormSubmissionCompleted: true,
        getFormSubmission: false,
      };
    case Actions.UPDATE_FORM_SUBMISSION:
      return {
        ...state,
        updateFormSubmission: true,
        updateFormSubmissionCompleted: false,
      };
    case Actions.UPDATE_FORM_SUBMISSION_COMPLETED:
      return {
        ...state,
        formSubmission: action.payload,
        updateFormSubmissionCompleted: true,
        updateFormSubmission: false,
      };
    case Actions.GET_FORM_SUBMISSIONS:
      return {
        ...state,
        getFormSubmissions: true,
        getFormSubmissionsCompleted: false,
      };
    case Actions.GET_FORM_SUBMISSIONS_COMPLETED:
      return {
        ...state,
        formSubmissionsData: action.payload.data,
        getFormSubmissionsCompleted: true,
        getFormSubmissions: false,
      };
    case Actions.GET_LOGBOOK:
      return {
        ...state,
        getLogbook: true,
        getLogbookCompleted: false,
      };
    case Actions.GET_LOGBOOK_COMPLETED:
      return {
        ...state,
        logbook: setIsFavorite(action.payload.data, state.favoriteLogbooks),
        getLogbookCompleted: true,
        getLogbook: false,
      };
    case Actions.SET_NETWORK_LOGBOOKS_TREE:
      return {
        ...state,
        networkLogbooksTree: action.payload,
      };
    case Actions.SET_SIDEBAR_CATEGORY_EXPANDED_STATUS:
      return {
        ...state,
        sidebarCategoryExpandedStatus: action.payload,
      };
    case Actions.SET_SIDEBAR_EXPANDED_STATUS:
      return {
        ...state,
        sidebarExpandedStatus: action.payload,
      };
    case Actions.SET_LOGBOOK_DETAIL_TO_PARENT_VIEW:
      return {
        ...state,
        isFromLogbookHomeDetail: action.payload,
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.SIGN_DOCUMENT:
      return {
        ...state,
        signFieldId: action.signFieldId,
      };
    case Actions.FETCH_ERROR:
      return {
        ...state,
        getFormSubmission: false,
        getFormSubmissionCompleted: false,
      };
    default:
      return state;
  }
}

function setIsFavorite(logbooks: ILogbook[], favoriteLogbooks: ILogbook[] | undefined): IHomeLogbook[] {
  return logbooks.map((logbook: ILogbook) => {
    return {
      ...logbook,
      isFavorite: favoriteLogbooks?.some((favorite: IHomeLogbook) => favorite.id === logbook.id),
    };
  });
}
